import {parseJwt} from "../../utils/parseJWT";

let isAdminLoggedIn = !!localStorage.getItem('isAdminLoggedIn')
let adminAccessToken = localStorage.getItem('adminAccessToken')
let adminRefreshToken = localStorage.getItem('adminRefreshToken')
let jwtExp = null

if(adminAccessToken)  {
    jwtExp = parseJwt(adminAccessToken).exp*1000
}


const state = () => ({
    isAdminLoggedIn,
    adminAccessToken,
    adminRefreshToken,
    refreshPromise: null,
    jwtExp,

    info: {
        id: null,
        email: null,
        fullName: null
    },
})

const mutations = {
    setAdminInfo(state, data) {
        if (data.id) state.info.id = data.id
        if (data.email) state.info.email = data.email
        if (data.fullName) state.info.fullName = data.fullName
    },
    adminLogin(state, data) {
        state.isAdminLoggedIn = true
        state.adminAccessToken = data.accessToken
        state.adminRefreshToken = data.refreshToken

        localStorage.setItem('isAdminLoggedIn', 'true')
        localStorage.setItem('adminAccessToken', data.accessToken)
        localStorage.setItem('adminRefreshToken', data.refreshToken)

        state.jwtExp = parseJwt( state.adminAccessToken).exp * 1000
    },
    adminLogout(state) {
        state.isAdminLoggedIn = false
        state.adminAccessToken = null

        localStorage.removeItem('isAdminLoggedIn')
        localStorage.removeItem('adminAccessToken')
        localStorage.removeItem('adminRefreshToken')
    },
    adminStoreDestroy(state) {
        state.info.email = null
        state.info.password = null
    },
    setRefreshPromise(state,promise){
        state.refreshPromise = promise
    },
    setAccessToken(state,token){
        state.adminAccessToken = token
        state.jwtExp = parseJwt(state.adminAccessToken).exp * 1000
    },
}

export default {state, mutations}
