const state = () => ({
    menuActive: true,
    themes: []
})

const mutations = {
    setMenuActive(state) {
        state.menuActive = !state.menuActive
    },
    setTypeLesson(state, type) {
        let lessonId = this.$route.params.lessonId
        state.lessons.find(lesson => lesson.id = lessonId).isPassed = type
    },
    'lessons.setThemesInfo'(state, themes) {
        state.themes = themes
    },
    'lessons.setPassingLesson'(state, lessonId) {
        let lessonIndex = null
        const index = state.themes.findIndex(item => {
            lessonIndex = item.lessons.findIndex(lesson => lesson.id === lessonId)
            return lessonIndex !== -1
        })
        if (index !== -1 && lessonIndex !== -1) {
            state.themes[index].lessons[lessonIndex].isCompleted = true
        }
    }
}

const getters = {
    menuActive(state) {
        return state.menuActive
    },
    themes(state) {
        return state.themes
    },
    lessons(state) {
        const lessons = []
        state.themes.forEach(item => lessons.push(...item.lessons))
        return lessons
    }
}

export default {state, mutations, getters}
