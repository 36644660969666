const state = () => ({
    init: false,
    
    typeCourses: true, /*true - текущие, false - пройденные */
    courses: [],

    searchCourseName: '',
    showBuyCoursePopup: false,
    showNotAvailableCourse: false
})

const mutations = {
    setTypeCourses(state, type) {
        state.typeCourses = type
    },
    'myCourses.setMyCourses'(state, myCourses) {
        state.init = true
        state.courses = myCourses
    },
    'myCourses.pushCourse'(state, courseId) {
        state.courses.push(courseId)
    },
    'myCourses.setShowNotAvailableCourse'(state, isShow) {
        state.showNotAvailableCourse = isShow
    },
    'myCourses.setShowBuyCoursePopup'(state, isShow) {
        state.showBuyCoursePopup = isShow
    },
    'myCourses.setSearchCourseName'(state, search){
        state.searchCourseName = search
    }
}

const getters = {
    typeCourses(state) {
        return state.typeCourses
    },
    myCourses(state) {
        return state.courses.filter(course => course.name.toLowerCase().startsWith(state.searchCourseName.toLowerCase()))
    },
    searchLine(state){
        return state.searchCourseName
    }
}

export default {state, mutations, getters}
