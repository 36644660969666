const state = () => ({
    init: false,
    
    categories: [],
    courses: [],
    
    selectedCategoryId: null,
    searchCourseName: ''
})

const mutations = {
    'courses.setInit'(state, data) {
        state.init = data
    },
    'courses.pushCategories'(state, categories) {
        state.categories.push(...categories)
    },
    'courses.pushCourses'(state, courses) {
        state.courses.push(...courses)
    },
    'course.setCourseInfo'(state, info) {
        const course = state.courses.find(item => item.id === info.id)
        if (course)
            course.info = info
        else
            state.courses.push(info)

    },
    
    'courses.setSelectedCategory'(state, categoryId) {
        state.selectedCategoryId = categoryId
    },
    'courses.setSearchCourseName'(state, search) {
        state.searchCourseName = search
    }
}

const getters = {
    getFilteredCourses(state) {
        const filterCoursesByCategory = state.selectedCategoryId ? state.courses
            .filter(item => item.categories.find(categoryId => categoryId === state.selectedCategoryId)) : state.courses
        if (state.searchCourseName) {
            return filterCoursesByCategory
                .filter(course => course.name.toLowerCase().startsWith(state.searchCourseName.toLowerCase()))
        } else {
            return filterCoursesByCategory
        }
    },
    getCourses(state) {
        return state.courses
    },
    getCategories(state) {
        return state.categories
    }
}

export default {state, mutations,getters}
