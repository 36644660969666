const state = () => ({
    defaultFonts: []
})

const mutations = {
    'certificates.setDefaultFonts'(state, data) {
        state.defaultFonts = data
    }
}

const getters = { }

export default {state, mutations, getters}
