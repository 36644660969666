import Vue from 'vue'
import App from './App.vue'

import store from '@/store'
import router from '@/router'
import Api from '@/config/api'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'
import Cookie from "js-cookie"

import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import VueDataTables from 'vue-data-tables'
import number from "./directives/number";
import VueMask from 'v-mask'
import VueScroller from 'vue-scrollto'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(VueYandexMetrika, {
  id: 87466482,
  router: router,
  env: process.env.NODE_ENV
})

Vue.use(VueScroller, {duration: 500, easing: "ease", offset: -100,})

Vue.filter('numFormat', numFormat(numeral))
Vue.prototype.$api = new Api()
Vue.prototype.$cookie = Cookie
Vue.use(PerfectScrollbar)
Vue.use(ElementUI)
Vue.use(VueDataTables)
Vue.use(VueMask)
Vue.directive("number",number)

Vue.config.productionTip = false
locale.use(lang)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
