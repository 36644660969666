const state = () => ({
    categories: [],
    courses: [],
    selectedCategory: null,
    selectedCourseId: null,

    progressNotify: null
})

const mutations = {
    setCategories(state, categories) {
        state.categories = categories
        if (state.categories.length) state.selectedCategory = state.categories[0]
    },
    addCategory(state, category) {
        state.categories.push(category)
        if (!state.selectedCategory) state.selectedCategory = category
    },
    deleteCategory(state, categoryId) {
        state.categories = state.categories.filter(categoryItem => categoryItem.id !== categoryId)

        if (categoryId === state.selectedCategory.id) {
            if (state.categories.length) {
                state.selectedCategory = state.categories[0]
            } else {
                state.selectedCourseId = null
            }
        }
        if (!state.categories.length) state.selectedCategory = null

        state.courses = state.courses.map(course => {
            if (course.categories.includes(categoryId)) {
                course.categories = course.categories.filter(category => category !== categoryId)
            }

            return course
        })
    },
    selectCategory(state, category) {
        state.selectedCategory = category
    },
    setCourses(state, courses) {
        state.courses = courses
    },
    deleteCourseById(state, courseId) {
        state.courses = state.courses.filter(course => course.id !== courseId)
        state.selectedCourseId = null
    },
    selectCourseId(state, selectedCourseId) {
        state.selectedCourseId = selectedCourseId
    },

    installProgressNotify(state, progressNotifyInstance) {
        state.progressNotify = progressNotifyInstance
    }
}


const getters = {
    categories(state) {
        return state.categories
    },
    courses(state) {
        return state.courses
    },
    selectedCategory(state) {
        return state.selectedCategory
    },
    selectedCourseId(state) {
        return state.selectedCourseId
    },
    progressNotify(state) {
        return state.progressNotify
    }
}

export default {state, mutations, getters}
