import Vue from 'vue'
import Vuex from 'vuex'

import admin from '@/app/admin/admin.store'
import payment from '@/app/user/payment/payment.store'
import course from '@/app/admin/course/course.store'
import user from '@/app/user/user.store'
import courses from '@/app/user/courses/courses.store'
import myCourses from '@/app/user/myCourses/myCourses.store'
import lessons from '@/app/user/lessonsCourse/lessonsCourse.store'
import certificates from '@/app/admin/certificates/certificates.store'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isOpenAuthModal: false,
        windowWidth: null,
        host: process.env.VUE_APP_BACKEND_HOST,
        lastRoute: null
    },
    mutations: {
        setWindowWidth(state, data) {
            state.windowWidth = data
        },
        setIsOpenAuthModal(state, isOpen) {
            state.isOpenAuthModal = isOpen
        },
        setLastRoute(state, route) {
            state.lastRoute = route
        }
    },
    actions: {},
    getters: {},
    modules: {
        admin,
        payment,
        course,
        user,
        courses,
        myCourses,
        lessons,
        certificates
    }
})
