const state = () => ({
    flagPayment: false,
})

const mutations = {
    setFlagNewUser(state, data = null) {
        if (data != null) state.flagPayment = data
    }
}

export default {state, mutations}
