export default {
    bind(el, binding) {
        if (!binding.value) return

        let element
        if (el.tagName === 'input') element = el
        else element = el.querySelector('input')

        const decLength = 2
        let isSelectedText = false

        if (binding.modifiers.integer) {
            element.addEventListener('keydown', (e) => {
                if (!e.isTrusted) return


                if (e.key >= '0' && e.key <= '9') return

                else if (e.ctrlKey) {
                    if (e.keyCode === 67 || e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 88 || e.keyCode === 90) return;

                } else if (e.key.length === 1) e.preventDefault()
            })

        } else {
            element.addEventListener('click', () => isSelectedText = false)
            element.addEventListener('input', () => isSelectedText = false)
            element.addEventListener('select', () => isSelectedText = true)
            element.addEventListener('blur', () => isSelectedText = false)

            element.addEventListener('keydown', (e) => {
                if (!e.isTrusted) return

                if (e.key === '.' && element.value.includes('.')) e.preventDefault()

                if (e.key >= '0' && e.key <= '9') {
                    const numberTokens = element.value.split('.')

                    const textBeforeCursor = element.value.slice(0, element.selectionStart)

                    if (!textBeforeCursor.includes('.')) return
                    else if (numberTokens[1] && numberTokens[1].length >= decLength && !isSelectedText) e.preventDefault()

                } else if (e.ctrlKey) {
                    if (e.keyCode === 67 || e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 88 || e.keyCode === 90) return

                } else if (e.key !== '.' && e.key.length === 1) e.preventDefault()
            })
        }

    },
}