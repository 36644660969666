import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import cookie from 'js-cookie'
Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/email-activation/:token',
            name: 'confirm-email',
            component: () => import('@/app/user/auth/confirm-email/ConfirmEmail')
        },
        {
            path: '/reset-password/:token',
            name: 'reset-password',
            component: () => import('@/app/user/auth/password-recovery/ResetPassword')
        },
        {
            path: '/append-information/:token',
            name: 'append-information',
            component: () => import('@/app/user/auth/append-info/AppendInfo')
        },
        {
            path: '/oauth',
            name: 'oauth',
            component: () => import('@/app/user/auth/oauth/OAuth')
        },
        {
            path: '/',
            component: () => import('@/app/user/MainWrapper'),
            children: [
                {
                    path: '/404',
                    name: 'page404',
                    component: () => import('@/app/user/components/Page404')
                },
                {
                    path: '/payment/finish',
                    name: 'finish',
                    component: () => import('@/app/user/payment/FinishPayment')
                },
                {
                    path: '/payment/error',
                    name: 'error',
                    component: () => import('@/app/user/payment/ErrorPayment')
                },
                {
                    path: 'course-info/:courseId',
                    name: 'course-info',
                    component: () => import('@/app/user/courseInfo/CourseInfo')
                },
                {
                    path: 'course-info/:courseId/program',
                    name: 'course-program',
                    component: () => import('@/app/user/courseProgram/CourseProgram'),
                    meta: {requiresAuth: true}
                },
                {
                    path: 'settings',
                    name: 'Settings',
                    component: () => import('@/app/user/settings/Settings'),
                    meta: {requiresAuth: true}
                },
                {
                    path: 'profile',
                    name: 'Profile',
                    component: () => import('@/app/user/profile/Profile'),
                    meta: {requiresAuth: true}
                },
                {
                    path: '/',
                    name: 'home',
                    component: () => import('@/app/user/home/Home')
                },
                {
                    path: '/courses',
                    name: 'courses',
                    component: () => import('@/app/user/courses/Courses')
                },
                {
                    path: '/my-courses',
                    name: 'my-courses',
                    component: () => import('@/app/user/myCourses/MyCourses'),
                    meta: {requiresAuth: true}
                }],
        },
        {
            path: '/course/:courseId/lessons/:lessonId',
            name: 'lessons-course',
            component: () => import('@/app/user/lessonsCourse/LessonsCourseWrapper'),
            meta: {requiresAuth: true}
        },
        {
            path: '/login',
            name: 'AuthWrapper',
            component: () => import('@/app/user/auth/AuthWrapper'),
        },
        {
            path: '/admin',
            name: 'AdminPanelWrapper',
            component: () => import('@/app/admin/AdminPanel'),
            children: [
                {
                    path: 'user-control',
                    name: 'AdminUserControl',
                    component: () => import('@/app/admin/adminUsers/AdminUsersControl')
                },
                {
                    path: 'courses',
                    name: 'admin-courses',
                    component: () => import('@/app/admin/course/CoursePageWrapper')
                },
                {
                    path: 'certificates',
                    name: 'certificates',
                    component: () => import('@/app/admin/certificates/Certificates')
                },
                {
                    path: 'purchases',
                    name: 'purchases',
                    component: () => import('@/app/admin/purchases/PurchasesWrapper')
                },
                {
                    path: 'configuration',
                    name: 'configuration',
                    component: () => import('@/app/admin/configuration/ConfigurationWrapper')
                }],
            meta: {requiresAdminAuth: true}
        },
        {
            path: '/admin/login',
            name: 'AdminLogin',
            component: () => import('@/app/admin/auth/Login')
        },
        {
            path: '*',
            component: () => import('@/app/user/MainWrapper'),
            children: [{
                path: '*',
                component: () => import('@/app/user/components/Page404')
            }]
        }],

    scrollBehavior(to,from) {
        if(to.path === from.path)
            return

        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        }
    }
});

export default router

router.beforeEach((to, from, next) => {

    store.commit('setLastRoute',from)

    if(to.query['utm_source'] || to.query['utm_medium'] ||
       to.query['utm_campaign'] || to.query['utm_content']){

        const utmInfo={}

        Object.keys(to.query).forEach(key=>{
            if( key==='utm_source' || key==='utm_medium' || key==='utm_campaign' || key==='utm_content')
                utmInfo[key]=to.query[key]
        })

        cookie.set('utmInfo', JSON.stringify(utmInfo), {expires: 30})
    }

    if (to.matched.some(record => record.meta.requiresAdminAuth)) {
        if (!store.state.admin.isAdminLoggedIn) {
            next('/admin/login')
        } else next()
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.state.user.isLoggedIn) {
            next('/login')
        } else next()
    } else next()
})
