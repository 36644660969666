import {parseJwt} from "../../utils/parseJWT";

let isLoggedIn = !!localStorage.getItem('isLoggedIn')
let accessToken = localStorage.getItem('accessToken')
let refreshToken = localStorage.getItem('refreshToken')

let jwtExp = null

if (refreshToken) {
    jwtExp = parseJwt(accessToken).exp * 1000
}

const state = () => ({
    isLoggedIn,
    accessToken,
    refreshToken,
    refreshPromise: null,
    jwtExp,

    info: {
        id: null,

        avatar: null,
        email: null,
        phone: null,
        fullName: null,
        birthday: null,
    },
})

const mutations = {

    setUserInfo(state, data) {
        if (data.id) state.info.id = data.id
        if (data.email) state.info.email = data.email
        if (data.fullName) state.info.fullName = data.fullName
        if (data.birthday) state.info.birthday = data.birthday
        if (data.phone) state.info.phone = data.phone
    },

    setUserAvatar(state, avatar) {
        if (avatar) state.info.avatar = avatar
    },
    deleteUserAvatar(state) {
        if (state.info.avatar) state.info.avatar = null
    },

    login(state, data) {
        state.isLoggedIn = true
        state.accessToken = data.accessToken
        state.refreshToken = data.refreshToken

        localStorage.setItem('isLoggedIn', 'true')
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('refreshToken', data.refreshToken)

        state.jwtExp = parseJwt(state.accessToken).exp * 1000
    },

    logout(state) {
        state.isLoggedIn = false
        state.accessToken = null

        localStorage.removeItem('isLoggedIn')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
    },

    userStoreDestroy(state) {
        state.info.id = null
        state.info.avatar = null
        state.info.email = null
        state.info.fullName = null
        state.info.birthday = null
    },

    setUserRefreshPromise(state, promise) {
        state.refreshPromise = promise
    },
    setAccessToken(state, token) {
        state.accessToken = token
        state.jwtExp = parseJwt(state.accessToken).exp * 1000
    },
}

const getters = {
    userInfo(state) {
        return state.info
    },
    userAvatar(state) {
        return state.info.avatar
    },
}

export default {state, mutations, getters}
