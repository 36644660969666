<template>
  <div id="app" :class="{'admin-login-page':this.$route.name === 'AdminLogin'}">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    this.initMetric()
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  methods: {
    resize: function () {
      this.$store.commit('setWindowWidth', document.documentElement.clientWidth)
    },
    initMetric() {
      const scriptDev = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
          '(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");ym(87474362, "init", {clickmap:true,trackLinks:true,accurateTrackBounce:true,ecommerce: \'dataLayer\'});'
      const scriptProd = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
          '(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(87466482, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true,ecommerce: \'dataLayer\'});'

      const script = document.createElement('script')
      script.type = 'text/javascript'

      if (process.env.NODE_ENV === 'production') {
        script.innerText = scriptProd

        const meta = document.createElement('meta');
        meta.name = "facebook-domain-verification";
        meta.content = "cpklcqq9wv01uxyicw5ohvji2r4dgb";
        document.head.append(meta)

        const scriptMetaPixel = document.createElement('script')
        scriptMetaPixel.type='text/javascript'
        scriptMetaPixel.innerText = '!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};' +
            'if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0];' +
            's.parentNode.insertBefore(t,s)}(window, document,\'script\', \'https://connect.facebook.net/en_US/fbevents.js\');' +
            'fbq(\'init\', \'3089411721332058\'); fbq(\'track\', \'PageView\');'
        document.head.append(scriptMetaPixel)

        const scriptVkPixel = document.createElement('script')
        scriptVkPixel.type='text/javascript'
        scriptVkPixel.innerText = '!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src=\'https://vk.com/js/api/openapi.js?169\',t.onload=function(){VK.Retargeting.Init("VK-RTRG-1327809-7kgWt"),VK.Retargeting.Hit()},document.head.appendChild(t)}();'
        document.head.append(scriptVkPixel)
      }
    else
        script.innerText = scriptDev

      document.head.append(script)
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/style";

body {
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
}
</style>
